<template>
    <div class="app-container">
        <el-card class="box-card">
            <!-- Filters / Search bar -->
            <div class="filters">
                <el-form :inline="true">
                    <el-form-item :label="$t('comm.complaints.limit')">
                        <el-select v-model="limit" placeholder="Limita" @change="onFilterChange" class="filter-select">
                            <el-option
                                v-for="item in [10, 50, 100, 250]"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('comm.complaints.status')">
                        <el-select
                            v-model="status"
                            placeholder="Status reclamatie"
                            @change="onFilterChange"
                            class="filter-select"
                        >
                            <el-option
                                v-for="item in statuses"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- Filter by complaint ID -->
                    <el-form-item :label="$t('comm.complaints.complaintId')">
                        <el-input
                            v-model="complaintId"
                            placeholder="Căutare după ID"
                            clearable
                            @keyup.enter.native="onFilterChange"
                            @clear="onFilterChange"
                        />
                    </el-form-item>

                    <!-- Filter by client name -->
                    <el-form-item :label="$t('comm.complaints.clientName')">
                        <el-input
                            v-model="clientName"
                            placeholder="Căutare după nume"
                            clearable
                            @keyup.enter.native="onFilterChange"
                            @clear="onFilterChange"
                        />
                    </el-form-item>
                </el-form>
            </div>

            <!-- Top Pagination -->
            <div class="pagination-wrapper">
                <el-pagination
                    layout="prev, pager, next, total"
                    :current-page="currentPage"
                    :total="total"
                    :page-size="limit"
                    @current-change="changePage"
                />
            </div>

            <!-- Complaints list -->
            <div class="complaints-list">
                <el-card v-for="(complaint, i) in complaints" :key="i" class="box-card item">
                    <div slot="header">
                        <span class="bold">R#{{ complaint.id }}</span>
                    </div>
                    <Item :item="complaint" />
                </el-card>
            </div>

            <!-- Bottom Pagination -->
            <div class="pagination-wrapper">
                <el-pagination
                    layout="prev, pager, next, total"
                    :current-page="currentPage"
                    :total="total"
                    :page-size="limit"
                    @current-change="changePage"
                />
            </div>
        </el-card>
    </div>
</template>

<script>
import createCommunicationStore from '@/store/modules/communication';
import { call, sync } from 'vuex-pathify';
const Item = () => import('./item');

export default {
    components: {
        Item,
    },
    data() {
        return {
            statuses: [
                { label: 'Toate', value: 1 },
                { label: 'Nerezolvate', value: 0 },
            ],
        };
    },
    computed: {
        ...sync('communication/complaints@', {
            total: 'total',
            complaints: 'data',
            limit: 'limit',
            start: 'start',
            status: 'all',
            complaintId: 'complaintId',
            clientName: 'clientName',
        }),
        currentPage() {
            // Calculate the current page from the start index and page limit
            return Math.floor(this.start / this.limit) + 1;
        },
    },
    methods: {
        getComplaints: call('communication/GetComplaints'),
        changePage(page) {
            this.start = (page - 1) * this.limit;
            this.getComplaints();
        },
        onFilterChange() {
            // Reset pagination on filter change
            this.start = 0;
            this.getComplaints();
        },
    },
    async mounted() {
        try {
            await this.getComplaints();
        } catch (err) {
            console.log(err);
            this.$reportToSentry(err, {
                extra: { fn: 'getComplaints' },
            });
        }
    },
    beforeCreate() {
        this.$store.registerModule('communication', createCommunicationStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('communication');
    },
};
</script>

<style scoped>
/* Filters / search row */
.filters {
    margin-bottom: 20px;
}

/* Slightly bigger selects */
.filter-select .el-input__inner {
    min-width: 160px;
}

/* Center pagination with a wrapper */
.pagination-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

/* Card items */
.complaints-list {
    margin-bottom: 20px;
}

.box-card.item {
    margin-bottom: 20px;
}

/* Bold text for complaint header */
.bold {
    font-weight: 700;
}
</style>
